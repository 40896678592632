import http from './axios'

export default {
  getCourses: (params) => {
    return http({
      method: 'get',
      url: `courses`,
      params: params
    })
  },
  getParentCourse: (courseId) => {
    return http({
      method: 'get',
      url: `courses/${courseId}`
    })
  },
  getCourseInstances: (courseId, showFinishedCourses) => {
    return http({
      method: 'get',
      url: `courses/${courseId}/instances`,
      params: {
        'show-finished': showFinishedCourses
      }
    })
  },
  getOneCourse: (courseId, instanceId) => {
    return http({
      method: 'get',
      url: `courses/${courseId}/instances/${instanceId}`
    })
  },
  getActiveCourseInstances: (params) => {
    return http({
      method: 'get',
      url: `courses/active`,
      params: params
    })
  },
  getCourseInstanceDevices: (courseId, instanceId) => {
    return http({
      method: 'get',
      url: `courses/${courseId}/instances/${instanceId}/loans`
    })
  },
  createParentCourse: (form) => {
    return http({
      method: 'post',
      url: 'courses',
      data: form
    })
  },
  createCourseInstance: (courseId, form) => {
    return http({
      method: 'post',
      url: `courses/${courseId}/instances`,
      data: form
    })
  },
  updateCourseInstance: (courseId, instanceId, form) => {
    return http({
      method: 'patch',
      url: `courses/${courseId}/instances/${instanceId}`,
      data: form
    })
  },
  getCoursesEndingSoon: (days) => {
    return http({
      method: 'get',
      url: `courses`,
      params: {
        expand: 'instances',
        dueWithin: days
      }
    })
  },
  extendCloseDate: (courseId, instanceId) => {
    return http({
      method: 'patch',
      url: `courses/${courseId}/instances/${instanceId}/extendClose`,
    })
  }
}
