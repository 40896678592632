<template>
  <div class="card">
    <div class="card-header bg-primary text-white font-weight-bold" v-if="hasHeader">
      <slot name="header"></slot>
    </div>
    <div class="card-body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppCard',
  computed: {
    hasHeader() {
      return !!this.$slots.header
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
