import devices from './devices'
import courses from './courses'
import users from './users'
import stats from './stats'
import auth from './auth'
import anonymous from './anonymous'

export default {
  devices,
  courses,
  users,
  stats,
  auth,
  anonymous
}
