<template>
  <thead>
    <tr>
      <slot></slot>
    </tr>
  </thead>
</template>

<script>

export default {
  name: 'AppTableHeader'
}
</script>
