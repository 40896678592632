<template>
  <span>{{ formattedDate }}</span>
</template>

<script>
export default {
  name: 'AppDate',
  props: {
    dateStr: {
      type: String
    }
  },
  computed: {
    formattedDate() {
      if (!this.dateStr) return null
      return Intl.DateTimeFormat('en-GB').format(new Date(this.dateStr))
    }
  }
}
</script>
