import http from './axios'

export default {
  getDevices: (params) => {
    return http({
      method: 'get',
      url: `devices`,
      params: params
    })
  },
  getOneDevice: (id) => {
    return http({
      method: 'get',
      url: `devices/${id}`
    })
  },
  getDeviceLoanHistory: (id) => {
    return http({
      method: 'get',
      url: `devices/${id}/loans`
    })
  },
  getActiveDeviceLoan: (id) => {
    return http({
      method: 'get',
      url: `devices/${id}/activeDeviceLoan`
    })
  },
  getDeviceLoanNotes: (device_id, loan_id) => {
    return http({
      method: 'get',
      url: `devices/${device_id}/loans/${loan_id}/notes`
    })
  },
  addDeviceLoanNote: (device_id, loan_id, note) => {
    return http({
      method: 'post',
      url: `devices/${device_id}/loans/${loan_id}/notes`,
      data: note
    })
  },
  updateDeviceLoan: (device_id, loan_id, loan) => {
    return http({
      method: 'patch',
      url: `devices/${device_id}/loans/${loan_id}`,
      data: loan
    })
  },
  getOverdueDevices: (params) => {
    return http({
      method: 'get',
      url: 'devices/overdue',
      params: params
    })
  },
  returnDevice: (id, data) => {
    return http({
      method: 'patch',
      url: `devices/${id}/return`,
      data
    })
  },
  extendLoan: (id, data) => {
    return http({
      method: 'patch',
      url: `devices/${id}/extend`,
      data: data
    })
  },
  uploadDeviceInventory: (form) => {
    return http({
      method: 'post',
      url: 'devices/upload',
      data: form,
      timeout: 0, // Disable timeout here
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  searchActiveLoans: (params) => {
    return http({
      method: 'get',
      url: 'devices/active/search',
      params: params
    })
  },
  deviceLoanActivity: (params) => {
    return http({
      method: 'get',
      url: 'devices/activity',
      params: params
    })
  }
}
