import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuelidate from 'vuelidate'
import VModal from 'vue-js-modal'

import pageTitle from '@/components/mixins/pageTitle'

Vue.mixin(pageTitle)

// Global Components
import AppCard from '@/components/AppCard/AppCard'
import AppDate from '@/components/format/AppDate'
import AppDateTime from '@/components/format/AppDateTime'
// Form Inputs
import AppInput from '@/components/Forms/Inputs/AppInput'
import AppTextarea from '@/components/Forms/Inputs/AppTextarea'
// Table
import AppTable from '@/components/AppTable/AppTable'
import AppTableHeaderCol from '@/components/AppTable/AppTableHeaderCol'
import AppTableBody from '@/components/AppTable/AppTableBody'
import AppTableEmptyBody from '@/components/AppTable/AppTableEmptyBody'
import AppTableRow from '@/components/AppTable/AppTableRow'
import AppTableCell from '@/components/AppTable/AppTableCell'

Vue.component('AppCard', AppCard)
Vue.component('AppDate', AppDate)
Vue.component('AppDateTime', AppDateTime)
// Forms
Vue.component('AppInput', AppInput)
Vue.component('AppTextarea', AppTextarea)
// Table
Vue.component('AppTable', AppTable)
Vue.component('AppTableHeaderCol', AppTableHeaderCol)
Vue.component('AppTableBody', AppTableBody)
Vue.component('AppTableEmptyBody', AppTableEmptyBody)
Vue.component('AppTableRow', AppTableRow)
Vue.component('AppTableCell', AppTableCell)

Vue.use(Vuelidate)
Vue.use(VModal, { dialog: true })

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
