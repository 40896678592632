import http from './axios'

export default {
  // DEVICES public routes
  devices: {
    getOneDeviceByBarcode: (barcode) => {
      return http({
        method: 'get',
        url: `public/devices/${barcode}`
      })
    },
    getActiveDeviceLoan: (barcode) => {
      return http({
        method: 'get',
        url: `public/devices/${barcode}/activeDeviceLoan`
      })
    },
    createDeviceLoan: (id, form) => {
      return http({
        method: 'post',
        url: `public/devices/${id}`, // ./checkout
        data: form
      })
    }
  },
  // COURSES public routes
  courses: {
    getAvailableCourses: (params) => {
      return http({
        method: 'get',
        url: `public/courses`,
        params: params
      })
    },
    getSingleCourse: (id) => {
      return http({
        method: 'get',
        url: `public/courses/${id}`
      })
    }
  }
}
