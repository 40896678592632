<template>
  <nav class="col-2 pt-2" v-if="$route.meta.private">
    <ul class="nav nav-pills flex-column">
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'Home' }" active-class="active" exact>Home</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'Devices' }" active-class="active">Devices</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'Courses' }" active-class="active">Courses</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'Users' }" active-class="active">Admin Users</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'Stats' }" active-class="active">Stats</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'Activity' }" active-class="active">Recent Activity</router-link>
      </li>
    </ul>
    <hr>
    <div class="text-center mt-4" v-if="isLoggedIn">
      <div class="font-weight-normal">Logged In as</div>
      <div>{{ loggedInUser.name }}</div>
      <div><router-link :to="{ name: 'Authenticate' }" class="mt-2 btn btn-sm btn-dark">Logout</router-link></div>
    </div>
    <div class="text-muted text-center mt-4">
      <small>{{ appVersion }}</small>
    </div>
  </nav>
</template>

<script>
import { store } from '@/store'

export default {
  name: 'Navigation',
  computed: {
    loggedInUser() {
      return store.user
    },
    isLoggedIn() {
      return !!store.user
    },
    appVersion() {
      return `v${process.env.VUE_APP_VERSION}`
    }
  }
}
</script>
