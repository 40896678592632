<template>
  <tr>
    <slot></slot>
  </tr>
</template>

<script>

export default {
  name: 'AppTableRow'
}
</script>
