<template>
  <div class="table-responsive">
    <table class="table table-striped border-0">
      <app-table-header class="bg-primary text-white">
        <slot name="header"></slot>
      </app-table-header>
      <slot></slot>
      <app-table-footer class="bg-primary text-white" v-if="hasFooter">
        <slot name="footer"></slot>
      </app-table-footer>
    </table>
  </div>
</template>

<script>
import AppTableHeader from '@/components/AppTable/AppTableHeader'
import AppTableFooter from '@/components/AppTable/AppTableFooter'

export default {
  name: 'AppTable',
  components: {
    AppTableHeader,
    AppTableFooter
  },
  computed: {
    hasFooter() {
      return !!this.$slots.footer
    }
  }
}
</script>
