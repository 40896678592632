import http from './axios'

export default {
  getUsers: () => {
    return http({
      method: 'get',
      url: `users`
    })
  },
  createUser: (data) => {
    return http({
      method: 'post',
      url: 'users',
      data: data
    })
  },
  updatePassword: (id, data) => {
    return http({
      method: 'patch',
      url: `users/${id}`,
      data: data
    })
  }
}
