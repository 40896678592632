<template>
  <td class="align-middle">
    <slot></slot>
  </td>
</template>

<script>

export default {
  name: 'AppTableCell'
}
</script>
