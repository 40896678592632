<template>
  <textarea class="form-control" :value="value" @input="handleInput" :class="classes" />
</template>

<script>
export default {
  name: 'AppTextarea',
  props: {
    value: {
      type: [String, Number]
    },
    validate: {
      type: Object,
      default: null
    }
  },
  computed: {
    classes() {
      if (!this.validate) return null
      return {
        'is-invalid': this.validate.$error && this.validate.$dirty,
        'is-valid': !this.validate.$invalid && this.validate.$dirty
      }
    }
  },
  methods: {
    handleInput(event) {
      if (this.validate) { this.validate.$touch() }
      this.$emit('input', event.target.value)
    }
  }
}
</script>
