import Vue from 'vue'
import VueRouter from 'vue-router'

import api from '@/api'
import { store } from '@/store'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    meta: { private: true, requireAuth: true }
  },
  {
    path: '/auth',
    name: 'Authenticate',
    component: () => import(/* webpackChunkName: "home" */ '../views/Authenticate.vue')
  },
  {
    path: '/devices',
    name: 'Devices',
    component: () => import(/* webpackChunkName: "devices" */ '../views/Devices.vue'),
    meta: { private: true, requireAuth: true }
  },
  {
    path: '/devices/:deviceId',
    component: () => import(/* webpackChunkName: "deviceParent" */ '../views/DeviceParent.vue'),
    props: true,
    meta: { private: true, requireAuth: true },
    children: [
      {
        path: '',
        name: 'ViewDevice',
        component: () => import(/* webpackChunkName: "viewDevice" */ '../views/ViewDevice.vue'),
        props: true,
        meta: { private: true, requireAuth: true },
      },
      {
        path: 'assign',
        name: 'AssignDevice',
        component: () => import(/* webpackChunkName: "assignDevice" */ '../views/AssignDevice.vue'),
        meta: { private: true, requireAuth: true },
      },
    ]
  },
  {
    path: '/courses',
    name: 'Courses',
    component: () => import(/* webpackChunkName: "courses" */ '../views/Courses.vue'),
    meta: { private: true, requireAuth: true }
  },
  {
    path: '/courses/create',
    name: 'CreateCourse',
    component: () => import(/* webpackChunkName: "createCourse" */ '../views/CreateCourse.vue'),
    meta: { private: true, requireAuth: true }
  },
  {
    path: '/courses/:courseId',
    name: 'ViewParentCourse',
    component: () => import(/* webpackChunkName: "viewParentCourse" */ '../views/ViewParentCourse.vue'),
    props: true,
    meta: { private: true, requireAuth: true }
  },
  {
    path: '/courses/:courseId/instance/:instanceId',
    name: 'ViewCourse',
    component: () => import(/* webpackChunkName: "viewCourse" */ '../views/ViewCourse.vue'),
    props: true,
    meta: { private: true, requireAuth: true }
  },
  {
    path: '/courses/:courseId/instance/:instanceId/edit',
    name: 'EditCourse',
    component: () => import(/* webpackChunkName: "editCourse" */ '../views/EditCourse.vue'),
    props: true,
    meta: { private: true, requireAuth: true }
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import(/* webpackChunkName: "users" */ '../views/Users.vue'),
    meta: { private: true, requireAuth: true }
  },
  {
    path: '/users/create',
    name: 'CreateUser',
    component: () => import(/* webpackChunkName: "createUsers" */ '../views/CreateUser.vue'),
    meta: { private: true, requireAuth: true }
  },
  {
    path: '/stats',
    component: () => import(/* webpackChunkName: "stats" */ '../views/Stats.vue'),
    name: 'Stats',
    meta: { private: true, requireAuth: true }
  },
  {
    path: '/activity',
    component: () => import(/* webpackChunkName: "activity" */ '../views/Activity.vue'),
    name: 'Activity',
    meta: { private: true, requireAuth: true }
  },
  {
    path: '/signout',
    component: () => import(/* webpackChunkName: "signout" */ '../views/DeviceLoanPortal.vue'),
    props: true,
    children: [
      {
        path: '',
        name: 'SignoutBarcode',
        component: () => import(/* webpackChunkName: "signout" */ '../components/DeviceLoanPortal/EnterBarcode.vue')
      },
    ]
  },
  {
    path: '/devices/:deviceBarcode/manage',
    component: () => import(/* webpackChunkName: "signoutDevice" */ '../views/DeviceLoanManagement.vue'),
    props: true,
    children: [
      {
        path: '',
        name: 'DeviceSignoutIndex',
        component: () => import(/* webpackChunkName: "signoutDevice" */ '../components/DeviceLoanManagement/DeviceSignout/DeviceSignout.vue')
      },
      {
        path: 'signout',
        name: 'DeviceSignout',
        component: () => import(/* webpackChunkName: "signoutDevice" */ '../components/DeviceLoanManagement/DeviceSignout/DeviceSignout.vue')
      },
      {
        path: 'signedout',
        name: 'DeviceSignedOut',
        component: () => import(/* webpackChunkName: "signoutDevice" */ '../components/DeviceLoanManagement/DeviceReturn/DeviceReturn.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requireAuth) && !store.user) {
    try {
      const userRequest = await api.auth.getAuthUser()
      store.user = userRequest.data
      next()
    } catch {
      next({ name: 'Authenticate' })
    }
  } else {
    next()
  }
})

export default router
