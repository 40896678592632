<template>
  <span v-if="dateStr">{{ formattedDate }}</span>
</template>

<script>
export default {
  name: 'AppDateTime',
  props: {
    dateStr: {
      type: String,
      required: true
    }
  },
  computed: {
    formattedDate() {
      return Intl.DateTimeFormat('en-GB', {
        dateStyle: 'short',
        timeStyle: 'short'
      }).format(new Date(this.dateStr))
    }
  }
}
</script>
