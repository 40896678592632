import http from './axios'

export default {
  getActiveTotal: () => {
    return http({
      method: 'get',
      url: `stats/device-loans/total`
    })
  },
  getActiveCountByModel: (params) => {
    return http({
      method: 'get',
      url: `stats/device-loans/by-model`,
      params
    })
  },
}
