<template>
  <tfoot>
    <tr>
      <slot></slot>
    </tr>
  </tfoot>
</template>

<script>

export default {
  name: 'AppTableFooter'
}
</script>
