<template>
  <app-table-body>
    <app-table-row>
      <app-table-cell :colspan="colspan">
        <slot />
      </app-table-cell>
    </app-table-row>
  </app-table-body>
</template>

<script>
import AppTableBody from '@/components/AppTable/AppTableBody'
import AppTableRow from '@/components/AppTable/AppTableRow'
import AppTableCell from '@/components/AppTable/AppTableCell'

export default {
  name: 'AppTableEmptyBody',
  components: {
    AppTableBody,
    AppTableRow,
    AppTableCell
  },
  props: {
    colspan: {
      type: [Number, String],
      default: 1
    }
  }
}
</script>
