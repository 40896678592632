import http from './axios'

export default {
  authenticate: (data) => {
    return http({
      method: 'post',
      url: `authenticate`,
      data: data
    })
  },
  getAuthUser: () => {
    return http({
      method: 'get',
      url: `users/me`
    })
  }
}
