<template>
  <tbody class="border-0">
    <slot></slot>
  </tbody>
</template>

<script>

export default {
  name: 'AppTableBody'
}
</script>
