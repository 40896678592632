import axios from 'axios'
import router from '@/router'
import { store } from '@/store'

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 5000
})

http.interceptors.request.use(config => {
  const token = localStorage.getItem('authToken')
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

http.interceptors.response.use(response => response, error => {
    const { status } = error.response
    if (status === 401) {
      store.user = null
      localStorage.removeItem('authToken')
      router.push({ name: 'Authenticate' })
    }
    return Promise.reject(error);
  }
)


export default http
