<template>
  <div id="app" class="container-fluid">
    <div class="row">
      <app-navigation />
      <main class="col">
        <div class="p-4">
          <router-view/>
        </div>
      </main>
    </div>
    <v-dialog />
  </div>
</template>

<script>
import AppNavigation from '@/components/Navigation/Navigation'

export default {
  name: 'App',
  components: {
    AppNavigation
  },
  async created() {

  }
}
</script>

<style lang="scss">
@import "./styles/theme";
@import "~bootstrap/scss/bootstrap";
$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";

nav {
  background: $gray-100;
  border-right: 1px solid $border-color;
  text-align:right;
  font-weight: 600;
  min-height: calc(100vh);
}

/* Dark Input Controls */
.card-header.bg-primary input.form-control, .card-header.bg-primary select.form-control, .card-header.bg-primary button.btn-primary {
  background: darken($primary, 10%);
  border:0;
}

/* Set form-control placeholder text value when using .text-white */
.form-control.text-white::placeholder {
  color:$white;
  opacity: 0.6;
}
</style>
