<template>
  <th class="align-middle">
    <slot></slot>
  </th>
</template>

<script>

export default {
  name: 'AppTableHeaderCol'
}
</script>
